import * as React from 'react';
import {Button} from 'react-bootstrap';
import {ReactNode} from 'react';

interface PageHeaderProps {
    action?: string;
    border?: boolean;
    centered?: boolean;
    children?: ReactNode;
    id?: string;
    onClick?: () => void;
    title?: string | ReactNode;
    type: `h1` | `h4`;
    wrap?: boolean;
}

export const PageHeader = ({action, border = true, centered = false, children, id, onClick, title, type, wrap = false}: PageHeaderProps) => (
    <>
        {type === `h1` && (
            <h1
                className={`tw-text-[28px] md:tw-text-[40px] lg:tw-text-[40px] tw-items-center tw-flex tw-justify-between${
                    wrap ? ' tw-flex-wrap' : ' tw-flex-nowrap'
                }${border ? ' tw-border-b tw-pb-0 md:tw-pb-1 lg:tw-pb-1 tw-mb-6' : ' tw-mb-3'}${centered ? ' tw-justify-center' : ' tw-justify-between'}`}
                id={id}
            >
                {title}
                {action && (
                    <Button
                        className="tw-mt-1"
                        onClick={onClick}
                        data-e2e="pageHeaderActionCreateNewList"
                        variant="link"
                    >
                        {action}
                    </Button>
                )}
                {children}
            </h1>
        )}
        {type === `h4` && (
            <h4
                className={`tw-text-2xl tw-font-bold tw-p-1 tw-flex tw-justify-between${wrap ? ' tw-flex-wrap' : ' tw-flex-nowrap'}${
                    border ? ' tw-border-b tw-mb-5' : ' tw-mb-3'
                }`}
                id={id}
            >
                {title}
                {action && (
                    <span>
                        <Button
                            onClick={onClick}
                            size="sm"
                            variant="link"
                        >
                            {action}
                        </Button>
                    </span>
                )}
                {children}
            </h4>
        )}
    </>
);
